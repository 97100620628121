import { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBracketSquare, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Popover } from "@headlessui/react";

export default function BookingPlaceholder({ handleSubmit, platform, ...props }) {
    const { current_staff, staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const { properties } = useSelector((state) => state.properties);
    const { appointments } = useSelector((state) => state.appointments);

    let regards = "The Qliving Team";
    if (process.env.REACT_APP_COLOUR === "UC") {
        regards = "The Urban Circle Team";
    } else if (process.env.REACT_APP_COLOUR === "QP") {
        regards = "The Quorum Properties Team";
    }

    const default_options = [
        {
            value: current_staff?.name,
            label: "me",
            link: "",
        },
        {
            value: "Kind Regards, " + regards,
            label: "regards",
            link: "",
        },
        { value: current_booking?.user?.name, label: "name", link: "" },
        { value: current_booking?.user?.surname, label: "surname", link: "" },
        { value: current_booking?.user?.email, label: "email", link: "" },

        {
            value: current_booking?.booking_reference,
            label: "booking reference",
            link: "",
        },
    ];

    let edit_link = "";
    if (current_booking?.edit_link !== null) {
        edit_link = current_booking?.edit_link?.edit_url;
    } else {
        edit_link = current_booking?.user.edit_url;
    }

    if (process.env.REACT_APP_COLOUR === "UC") {
        default_options.push({ value: window.location.origin + "/step1/" + edit_link, label: "Choose Bed + Edit Profile", link: "Click here to choose a bed" });
        default_options.push({ value: window.location.origin + "/documents/" + edit_link, label: "Documents Link", link: "Upload Documents" });
        default_options.push({ value: window.location.origin + "/bank-details/", label: "Bank Details Link", link: "Bank Details Update" });
        default_options.push({ value: window.location.origin + "/edit_nok/" + edit_link, label: "Edit Next of Kin", link: "Next of Kin" });
        default_options.push({ value: "https://urbancircle.simplybook.me/", label: "Appointments Link", link: "Appointments" });
    }

    if (process.env.REACT_APP_COLOUR === "UC" && current_booking?.property_id > 0) {
        default_options.push({ value: window.location.origin + "/edit_application/" + edit_link, label: "Edit Profile", link: "Your Profile" });
        default_options.push({ value: window.location.origin + "/payment/" + edit_link, label: "Payment Link", link: "Pay Here" });
        default_options.push({ value: process.env.REACT_APP_LARAVEL_URL + "cancellation/" + edit_link, label: "Cancellation Form", link: "Cancellation Form" });

        default_options.push({
            value:
                window.location.origin +
                "/step3/" +
                edit_link +
                "/" +
                current_booking?.property_id +
                "/" +
                current_booking?.student_type +
                "/" +
                current_booking?.user?.profile?.study_year +
                "/" +
                current_booking?.user?.profile?.gender +
                "/" +
                current_booking?.unit_type?.id +
                "/0",
            label: "Choose a bed",
            link: "Choose a bed",
        });

        default_options.push({
            value: "https://bit.ly/UrbanCircleReview",
            label: "Google Review",
            link: "Google Review",
        });
    } else if (process.env.REACT_APP_COLOUR === "QL") {
        default_options.push({ value: window.location.origin + "/edit_application/" + edit_link, label: "Edit Profile", link: "Your Profile" });
        default_options.push({ value: window.location.origin + "/bank-details/", label: "Bank Details Link", link: "Bank Details Update" });

        if (window.location.hostname == "apply.qliving.co.za") {
            default_options.push({ value: process.env.REACT_APP_LARAVEL_URL + "cancellation/" + edit_link, label: "Cancellation Form", link: "Cancellation Form" });
        }

        default_options.push({ value: window.location.origin + "/documents/" + edit_link, label: "Documents Link", link: "Upload Documents" });

        if (window.location.hostname == "apply.qliving.co.za") {
            default_options.push({ value: "https://www.qholdings.co.za/properties/the-ox/", label: "The Ox", link: "The Ox" });
            default_options.push({ value: "https://www.qholdings.co.za/properties/the-unison/", label: "Unison", link: "The Unison" });
            default_options.push({ value: "https://www.qholdings.co.za/properties/forest-views/", label: "Forest", link: "Forest Views" });
            default_options.push({ value: "https://www.qholdings.co.za/properties/delve-deeper/", label: "Delve Deeper", link: "Delve Deeper" });
            default_options.push({
                value: "https://wa.me/27212066516?text=To%20start%20writing%20a%20review%20about%20Quorum%20Property%20Management%20TAP%20THE%20SEND%20ARROW ",
                label: "Hello Peter",
                link: "Hello Peter",
            });
        }

        if (current_booking) {
            if (window.location.hostname == "apply.qliving.co.za") {
                default_options.push({
                    value: "https://g.co/kgs/jve3Kv6",
                    label: "Google Review",
                    link: "Google Review",
                });
            }
            // default_options.push({
            //     value:
            //         current_booking.property_id == 1
            //             ? "https://calendly.com/theox-1/move-in-inspection"
            //             : current_booking.property_id == 2
            //                 ? "https://calendly.com/forestviews/30min-1"
            //                 : current_booking.property_id == 4
            //                     ? "https://calendly.com/delvedeeper/move-in-inspection"
            //                     : current_booking.property_id == 3 || current_booking.property_id == 6 || current_booking.property_id == 7
            //                         ? "https://calendly.com/the-unison/move-in-inspection"
            //                         : "",
            //     label: "move in inspection",
            //     link: "Move in Inspection",
            // });
            // default_options.push({
            //     value:
            //         current_booking.property_id == 1
            //             ? "https://calendly.com/theox-1/move-out-inspection"
            //             : current_booking.property_id == 2
            //                 ? "https://calendly.com/forestviews/move-out-inspection"
            //                 : current_booking.property_id == 4
            //                     ? "https://calendly.com/delvedeeper/move-out-inspection"
            //                     : current_booking.property_id == 3 || current_booking.property_id == 6 || current_booking.property_id == 7
            //                         ? "https://calendly.com/the-unison/move-out"
            //                         : "",
            //     label: "move out inspection",
            //     link: "Move out Inspection",
            // });
            // default_options.push({
            //     value:
            //         current_booking.property_id == 1
            //             ? "https://calendly.com/theox-1/viewing-at-the-ox"
            //             : current_booking.property_id == 2
            //                 ? "https://calendly.com/forestviews/book-a-viewing"
            //                 : current_booking.property_id == 4
            //                     ? "https://calendly.com/delvedeeper/viewings"
            //                     : current_booking.property_id == 3 || current_booking.property_id == 6 || current_booking.property_id == 7
            //                         ? "https://calendly.com/the-unison/30min"
            //                         : "",
            //     label: "book a viewing",
            //     link: "Book a viewing",
            // });

            // default_options.push({
            //     value:
            //         current_booking.property_id == 1
            //             ? "https://calendly.com/theox-1/maintenance-call"
            //             : current_booking.property_id == 2
            //                 ? "https://calendly.com/forestviews/maintenance-call"
            //                 : current_booking.property_id == 4
            //                     ? "https://calendly.com/delvedeeper/maintenance-call"
            //                     : current_booking.property_id == 3 || current_booking.property_id == 6 || current_booking.property_id == 7
            //                         ? "https://calendly.com/the-unison/maintenance-at-the-unison"
            //                         : "",
            //     label: "maintenance call",
            //     link: "Maintenance Call",
            // });

            if (current_booking?.rates[current_booking?.rates.length - 1]?.signed_lease_at) {
                var object = new Object();
                object.value = process.env.REACT_APP_LARAVEL_URL + "move-in-form/" + edit_link;
                object.label = "move in form";
                object.link = "Your Move In Form";

                default_options.push(object);
            }

            if (current_booking?.inspection === 1 && current_booking.move_out_url !== null) {
                var object = new Object();
                // object.value = process.env.REACT_APP_LARAVEL_URL + "move-out-form/" + edit_link;
                object.value = process.env.REACT_APP_LARAVEL_URL + "move-out-form/" + current_booking.move_out_url;
                object.label = "move out form";
                object.link = "Your Move Out Form";

                default_options.push(object);
            }
        }

        if (window.location.hostname == "apply.quorumproperties.co.za") {
            default_options.push({
                value: "https://sales.the-dawn.co.za/",
                label: "Interactive Price List",
                link: "Interactive Price List",
            });
            default_options.push({
                value: "https://the-dawn.co.za/",
                label: "Website",
                link: "Website",
            });
            default_options.push({
                value: "https://the-dawn.co.za/faq/",
                label: "FAQ’s",
                link: "FAQ’s",
            });
            default_options.push({
                value: "https://my.ooba.co.za/obi/?var1=NV3&var2=DAWN99&var3=OBIDV&var4=Unknown",
                label: "Pre-Approval",
                link: "Pre-Approval",
            });
            default_options.push({
                value: "https://api.quorumproperties.co.za/wa_images/The_Dawn_Brochure.pdf",
                label: "Brochure",
                link: "Brochure",
            });
        }

        // default_options.push({ value: window.location.origin, label: "Apply", link: "Apply Page" });
    } else if (process.env.REACT_APP_COLOUR === "QP") {
        default_options.push({ value: window.location.origin + "/edit_application/" + edit_link, label: "Edit Profile", link: "Your Profile" });
        default_options.push({ value: process.env.REACT_APP_LARAVEL_URL + "cancellation/" + edit_link, label: "Cancellation Form", link: "Cancellation Form" });
        default_options.push({ value: window.location.origin + "/documents/" + edit_link, label: "Documents Link", link: "Upload Documents" });

        if (window.location.hostname == "charlie.romiapp.co.za" || window.location.hostname == "localhost") {
            default_options.push({
                value: "https://sales.charlieandthechairman.co.za/",
                label: "Interactive Price List",
                link: "Interactive Price List",
            });
            default_options.push({
                value: "https://charlieandthechairman.co.za/",
                label: "Website",
                link: "Website",
            });
            default_options.push({
                value: "https://sales.charlieandthechairman.co.za/?unitType=One+Bedroom",
                label: "One Beds",
                link: "One Beds",
            });
            default_options.push({
                value: "https://sales.charlieandthechairman.co.za/?unitType=Two+Bedroom",
                label: "Two Beds",
                link: "Two Beds",
            });
            default_options.push({
                value: "https://sales.charlieandthechairman.co.za/?unitType=Two+Bedroom+Penthouse",
                label: "Two Bed Penthouse",
                link: "Two Bed Penthouse",
            });
            default_options.push({
                value: "https://sales.charlieandthechairman.co.za/?unitType=Aparthotel+Suite",
                label: "ApartHotel Suites",
                link: "ApartHotel Suites",
            });
            default_options.push({
                value: "https://digiapp.betterbond.co.za/",
                label: "Pre-Approval",
                link: "Pre-Approval",
            });
            default_options.push({
                value: "https://paystack.com/pay/Charlieandthechairman",
                label: "Manual Paystack Payment",
                link: "Manual Paystack Payment",
            });
        }
    }

    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let [options, setOptions] = useState(default_options);
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
    });

    const addPlaceholder = (placeholder, link) => {
        if (platform === "email" && link && link !== "") {
            placeholder = "<a href='" + placeholder + "' target='_blank'>" + link + "</a>";
        }

        props.setFieldValue("message", props.value + " " + placeholder);
    };

    let chosen_property = "",
        chosen_unit = "",
        chosen_unit_type = "";

    useEffect(async () => {
        var option_array = default_options;
        if (current_booking?.property_id) {
            let units = await getUnits(current_booking.property_id);
            let unit_types = await getUnitTypes(current_booking.property_id);
            chosen_property = properties.find((p) => p.value === current_booking.property_id);

            if (chosen_property) {
                var object = new Object();
                object.value = chosen_property.label;
                object.label = "property";
                object.link = "";
                option_array.push(object);
            }

            appointments.forEach(function (appointment) {
                if (process.env.REACT_APP_COLOUR === "QL") {
                    if (appointment.name == "Viewing") {
                        if (appointment.show_unit === null || (appointment.show_unit === 1 && current_booking.unit_id !== null)) {
                            var object = new Object();
                            object.value = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            object.label = appointment.link_shortcode + " | " + appointment.property.name.split(" - ")[0];
                            object.link = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            option_array.push(object);
                        }
                    } else if (appointment.property_id === current_booking.property_id || ((current_booking.property_id === 7 || current_booking.property_id === 3) && appointment.property_id === 6)) {
                        if (appointment.show_unit === null || (appointment.show_unit === 1 && current_booking.unit_id !== null)) {
                            var object = new Object();
                            object.value = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            object.label = appointment.link_shortcode;
                            object.link = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            option_array.push(object);
                        }
                    }
                } else {
                    if (appointment.name == "Viewing") {
                        if (appointment.show_unit === null || (appointment.show_unit === 1 && current_booking.bed_id !== null)) {
                            var object = new Object();
                            object.value = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            object.label = appointment.link_shortcode + " | " + appointment.property.name.split(" - ")[0];
                            object.link = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            option_array.push(object);
                        }
                    } else if (appointment.property_id === current_booking.property_id) {
                        if (appointment.show_unit === null || (appointment.show_unit === 1 && current_booking.bed_id !== null)) {
                            var object = new Object();
                            object.value = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            object.label = appointment.link_shortcode;
                            object.link = window.location.origin + "/appointment/" + appointment.link_url + "/" + appointment.id + "/0/0/" + edit_link;
                            option_array.push(object);
                        }
                    }
                }
            });

            if (process.env.REACT_APP_COLOUR === "UC") {
                var object = new Object();
                object.value = "https://form.asana.com/?k=SF3rR1q-aiJlSyjEL2TGMg&d=1206403811071195";
                object.label = "Maintenance Call New";
                object.link = "Maintenance Call New";

                option_array.push(object);
            }

            if (process.env.REACT_APP_COLOUR === "UC") {
                var object = new Object();
                object.value = "https://www.hellopeter.com/axio-connect";
                object.label = "Axio Wi Fi Complaint";
                object.link = "Axio Wi Fi Complaint";

                option_array.push(object);
            }

            if (units.length > 0) {
                if (current_booking.unit_id) {
                    chosen_unit = units.find((p) => p.value === current_booking.unit_id);

                    if (chosen_unit) {
                        var object = new Object();
                        object.value = chosen_unit.label;
                        object.label = "unit name";
                        object.link = "";

                        option_array.push(object);
                    }
                }
            }

            if (current_booking.unit_type) {
                chosen_unit_type = unit_types.find((p) => p.value === current_booking.unit_type);

                if (chosen_unit_type) {
                    var object = new Object();
                    object.value = chosen_unit_type.label;
                    object.label = "unit type";
                    object.link = "";

                    option_array.push(object);
                }
            }
        }

        if (current_booking?.invoice_url) {
            var object = new Object();
            object.value = process.env.REACT_APP_LARAVEL_URL + "invoice/" + current_booking.invoice_url;
            object.label = "invoice link";
            object.link = "Your Invoice";

            option_array.push(object);
        }

        if (current_booking?.lease_url) {
            var object = new Object();
            object.value = process.env.REACT_APP_LARAVEL_URL + "lease/" + current_booking.lease_url;
            object.label = "lease link";
            object.link = "Your Lease";

            option_array.push(object);
        }

        if (current_booking?.surety_url) {
            option_array.push({ value: process.env.REACT_APP_LARAVEL_URL + "surety/" + current_booking.surety_url, label: "Surety Form", link: "Surety Form" });
        }

        if (current_booking?.lease_joint_url) {
            var object = new Object();
            object.value = process.env.REACT_APP_LARAVEL_URL + "lease/" + current_booking.lease_joint_url;
            object.label = "lease joint applicant link";
            object.link = "Your Lease";

            option_array.push(object);
        }

        if (process.env.REACT_APP_COLOUR === "UC" && current_booking?.rates[current_booking?.rates.length - 1]?.signed_lease_at) {
            var object = new Object();
            object.value = process.env.REACT_APP_LARAVEL_URL + "move-in-form/" + edit_link;
            object.label = "move in form";
            object.link = "Your Move In Form";

            option_array.push(object);
        }

        if (current_booking?.user?.mda_tenant_id > 0) {
            var object = new Object();
            object.value = current_booking?.user?.mda_tenant_id;
            object.label = "Tenant Code";
            object.link = "Tenant Code";

            option_array.push(object);
        }

        setOptions(option_array);
    }, [current_booking, setOptions]);

    async function getUnits(id) {
        let resp = axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            return response.data;
        });

        return resp;
    }

    async function getUnitTypes(id) {
        let resp = axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            return response.data;
        });

        return resp;
    }

    return (
        <Popover>
            <Popover.Button
                ref={setReferenceElement}
                className="ml-4 relative inline-block text-white bg-primary px-2  py-1 rounded-xl hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
            >
                <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faBracketSquare} />
            </Popover.Button>

            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="bg-white rounded-lg pb-4  z-20">
                <h1 className="bg-dark_background text-white p-2 rounded-t-lg">Shortcodes</h1>
                <div className="flex flex-col items-start px-4 mt-2">
                    {options?.map((o) => (
                        <button key={"bpopt_" + o.value} onClick={() => addPlaceholder(o.value, o.link)} type="button" className="text-gray-600 hover:text-primary">
                            [{o.label}]
                        </button>
                    ))}
                </div>
            </Popover.Panel>
        </Popover>
    );
}
